

import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import { storageGet, storageSet } from "./libs/storage";

import config from './locales/config';

const LANGUAGES = {...config.languages};
const NAMESPACES = [...config.namespaces];
const ALTCODES = {...config.altcodes};
const ALTCODESREVERSE = {};

for (const locale in ALTCODES) {
    ALTCODESREVERSE[ALTCODES[locale]] = locale;
}

const resources = {};

for (const locale in LANGUAGES) {
    try {
        let namespaceTrans = {};
        for (const namespace of NAMESPACES) {
            namespaceTrans[namespace] = require(`./locales/${locale}/${namespace}.json`);
        }
        resources[locale] = namespaceTrans;
    }
    catch (err) {
        console.error(err);
    }
}

let lastLang = storageGet('last_lang');

if (!lastLang) {
    lastLang = 'en';
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: Object.keys(LANGUAGES),
        lng: lastLang,
        fallbackLng: "en",
    });

i18n.on('languageChanged', (lang) => {
    storageSet('last_lang', lang);
});

export { LANGUAGES, NAMESPACES, ALTCODES, ALTCODESREVERSE };