import axios from 'axios';

import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n.js';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
  try {
    await axios.post('/api/auth/logout', {}, {'headers': {'Accept': 'application/json'}});
  } catch (err) {
    if (err.response.status !== 403) {
      console.error(err);
    }
  }
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
