import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../i18n';

import { ReactComponent as LanguageSvg } from './assets/images/icons/language.svg';

import DropDown from '../DropDown';

import './index.css';

let languages = [];
for (let locale in LANGUAGES) {
    languages.push({
        id: locale,
        caption: LANGUAGES[locale]
    });
}

function LanguageSelector() {
    const { i18n } = useTranslation();

    const changeLanguage = (langId) => () => {
        i18n.changeLanguage(langId);
    };

    const augmentedLanguages = useMemo(() => {
        const result = [...languages];
        result.sort((a, b) => a.id === i18n.language && b.id !== i18n.language ? -1 : 1);
        return result;
    }, [i18n.language]);

    return (
        <>
            <DropDown 
                alignment='bottom-left'
                toggleComponent={
                    <div className='language-icon'>
                        <LanguageSvg />
                        <span className='language-icon-caption'>
                            { languages.find((language) => language.id === i18n.language).caption }
                        </span>
                    </div>
                }
                contentComponent={
                    <div className='language-content'>
                        {
                            augmentedLanguages.map((language) => (
                                <div 
                                    key={language.id}
                                    className={`language-content-item ${language.id === i18n.language ? 'language-content-item-active' : ''}`} 
                                    onClick={changeLanguage(language.id)}
                                >
                                    { language.caption }
                                </div>
                            ))
                        }
                    </div>
                }
            />
        </>
    );
}

export default LanguageSelector;