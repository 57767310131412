import '../index.css';
function TextField(props) {
    const {
        id,
        caption = null,
        value = null,
        readonly = false,
        inputRef = null,
        onChange = () => { },
        icon = null,
        iconPlacement='left',
        password = false,
    } = props;

    return (
        <div className="input-field">
            {
                caption && 
                <label className="input-label" htmlFor={id}>
                    {caption}
                </label>
            }
            {
                !icon &&
                <input
                    ref={inputRef}
                    className="input-text"
                    type={password ? 'password' : 'text'}
                    id={id}
                    name={id}
                    value={value || ''}
                    disabled={readonly}
                    onChange={onChange}
                />
            }
            {
                icon &&
                <label 
                    htmlFor={id}
                    className="input-text input-text-icon"
                >
                    {
                        iconPlacement === 'left' &&
                        icon
                    }
                    <input
                        ref={inputRef}
                        className="input-text-inner"
                        type={password ? 'password' : 'text'}
                        id={id}
                        name={id}
                        value={value || ''}
                        disabled={readonly}
                        onChange={onChange}
                    />
                    {
                        iconPlacement === 'right' &&
                        icon
                    }
                </label>
            }
        </div>
    );
}

export default TextField;
