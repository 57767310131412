import { useEffect, useId, useState, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';

import './index.css';

function DropDown(props, ref) {
    const {
        toggleComponent=<></>,
        contentComponent=<></>,
        toggleType='click',
        position='bottom-left',
        onClose=() => {},
        onShow=() => {},
    } = props;

    const dropdownId = useId();
    const contentRef = useRef(null);
    const toggleRef = useRef(null);

    const [ show, setShow ] = useState(false);
    const [ toggleDimension, setToggleDimension ] = useState([0, 0]);
    const [ contentDimension, setContentDimension ] = useState([0, 0]);

    const positionToggleStyle = useMemo(() => {
        let result;
        if (position.startsWith('bottom') || position.startsWith('top')) {
            result = {
                height: '100%',
            };
        }
        else {
            result = {
                height: 'auto',
            };
        }
        return result;
    }, [position])

    const positionContentStyle = useMemo(() => {
        let result;

        switch (position) {
            case 'bottom-left':
                result = {
                    left: 0
                };
                break;
            case 'bottom-right':
                result = {
                    right: 0
                };
                break;
            case 'top-left':
                result = {
                    bottom: contentDimension[1] + toggleDimension[1],
                    left: 0,
                };
                break;
            case 'top-right':
                result = {
                    bottom: contentDimension[1] + toggleDimension[1],
                    right: 0,
                };
                break;
            case 'left-bottom':
                result = {
                    bottom: 0,
                    right: toggleDimension[0],
                };
                break;
            case 'left-top':
                result = {
                    top: 0,
                    right: toggleDimension[0],
                };
                break;
            case 'right-bottom':
                result = {
                    bottom: 0,
                    left: toggleDimension[0],
                };
                break;
            case 'right-top':
                result = {
                    top: 0,
                    left: toggleDimension[0],
                };
                break;
            default:
                throw new Error(`Position '${position}' not supported`)
        }

        return result;
    }, [contentDimension, toggleDimension, position]);

    const showDropdown = () => {
        if (toggleType === 'hover') {
            setShow(true);
        }
    };

    const hideDropdown = () => {
        if (toggleType === 'hover') {
            setShow(false);
            onClose();
        }
    };

    const toggleDropdown = () => {
        if (toggleType === 'click') {
            setShow((prevShow) => !prevShow);

            if (show) {
                onClose();
            }
        }
    };

    useImperativeHandle(ref, () => ({
        show: () => setShow(true),
        hide: () => setShow(false),
    }), []);

    useEffect(() => {
        if (toggleType === 'click' && show) {
            const hideOnForeignClick = (event) => {
                const {
                    target
                } = event;
                if (!(dropdownId === target.id || document.getElementById(dropdownId).contains(target))) {
                    setShow(false);
                    onClose();
                }
            };
            document.getElementsByTagName('body')[0].addEventListener('click', hideOnForeignClick);
            return () => document.getElementsByTagName('body')[0].removeEventListener('click', hideOnForeignClick);
        }
    }, [ dropdownId, toggleType, show, onClose ]);

    useEffect(() => {
        if (show) {
            onShow();
        }
    }, [show, onShow]);

    useEffect(() => {
        if (contentRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                setContentDimension([entries[0].target.offsetWidth, entries[0].target.offsetHeight]);
            });
            resizeObserver.observe(contentRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [contentRef]);

    useEffect(() => {
        if (toggleRef.current) {
            const resizeObserver = new ResizeObserver((entries) => {
                setToggleDimension([entries[0].target.offsetWidth, entries[0].target.offsetHeight]);
            });
            resizeObserver.observe(toggleRef.current);
            return () => resizeObserver.disconnect();
        }
    }, [toggleRef]);

    return (
        <div 
            id={dropdownId}
            className={`dropdown ${show ? 'dropdown-active' : ''}`} 
            onMouseEnter={showDropdown} 
            onMouseLeave={hideDropdown} 
        >
            <div 
                ref={toggleRef}
                className='dropdown-toggle'
                style={{
                    ...positionToggleStyle
                }}
                onClick={toggleDropdown}
            >
                { toggleComponent }
            </div>
            {
                show &&
                <div
                    ref={contentRef}
                    style={{
                        ...positionContentStyle
                    }} 
                    className='dropdown-menu'
                >
                    { contentComponent }
                </div>
            }
        </div>
    );
}

export default forwardRef(DropDown);