import { useMemo } from 'react';
import './index.css';

function CheckField(props) {
    const {
        id,
        caption=null,
        value=[],
        options=[],
        onChange=() => {},
    } = props;

    const augmentedValue = useMemo(() => {
        let result = {};

        for (let option of options) {
            result[option.id] = value.find((item) => item === option.id) !== undefined;
        }

        return result;
    }, [options, value]);

    const handleOnChange = (event) => {
        const {
            target
        } = event;

        let result_obj = {...augmentedValue};
        result_obj[target.value] = !result_obj[target.value];

        let result = [];
        for (let key in result_obj) {
            if (result_obj[key]) {
                result.push(key);
            }
        }

        onChange({
            ...event,
            target: {
                ...target,
                value: [...result]
            }
        });
    };

    return (
        <div className="input-field">
            {
                caption &&
                <label
                    className="input-label"
                    htmlFor={id}
                >
                    { caption }
                </label>
            }
            <div
                className='input-checkbox-groups'
            >
                {
                    options.map((option) => (
                        <div
                            key={option.id}
                            className='input-checkbox-group'
                        >
                            <input
                                id={option.id}
                                name={id}
                                className='input-checkbox'
                                type='checkbox' 
                                value={option.id}
                                checked={augmentedValue[option.id]}
                                onChange={handleOnChange}
                            />
                            <label 
                                className='input-checkbox-label'
                                htmlFor={option.id}
                            >
                                {option.caption}
                            </label>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CheckField;