const validateInRangeString = (data, formData, params) => {
    return typeof(data) === 'string' && data.length >= params.min && data.length <= params.max;
};

const validateNotEmpty = (data) => {
    return data !== null && data !== '' && data !== undefined;
};

const validateIsIdentical = (data, formData, params) => {
    let valid = true;

    if ('key' in params) {
        if (Array.isArray(params.key)) {
            for (const key of params.key) {
                if (data !== formData[key]) {
                    valid = false;
                    break;
                }
            }
        }
        else if (data !== formData[params.key]) {
            valid = false;
        }
    }
    else {
        valid = false;
    }

    return valid;
};

const VALIDATIONTYPE = {
    notEmpty: validateNotEmpty,
    isIdentical: validateIsIdentical,
    inRangeString: validateInRangeString,
};

class Validator {
    constructor(errorMessages) {
        this._errorMessages = errorMessages;
    }

    _getError(validate, data, formData, field, fields) {
        let validationType;
        let params;

        if (typeof(validate) === 'string') {
            validationType = validate;
        }
        else {
            validationType = validate.type;
            params = validate.params;
        }

        if (validationType in VALIDATIONTYPE) {
            if (!VALIDATIONTYPE[validationType](data, formData, params, field, fields)) {
                if (validationType in this._errorMessages && this._errorMessages[validationType](data, formData, params, field, fields)) {
                    return this._errorMessages[validationType](data, formData, params, field, fields);
                }
                else {
                    return validationType;
                }
            }
            else {
                return null;
            }
        }
        else {
            throw new Error(`${validationType} is unknown. Available validation types are [${Object.keys(VALIDATIONTYPE).join(', ')}].`);
        }
    }

    validate(fields, formData) {
        let error = null;
    
        for (const field of fields) {
            if ('validate' in field) {
                if (Array.isArray(field.validate)) {
                    for (const validate of field.validate) {
                        error = this._getError(validate, formData[field.id], formData, field, fields);
                        if (error) {
                            break;
                        }
                    }
                }
                else {
                    error = this._getError(field.validate, formData[field.id], formData, field, fields);
                }
            }

            if (error) {
                break;
            }
        }
    
        return error;
    };
}

export { Validator };