import '../index.css';
function SelectField(props) {
  const {
    id,
    caption = null,
    value = null,
    options = [],
    onChange = () => {},
  } = props;

  return (
    <div className="input-field">
      {caption && (
        <label className="input-label" htmlFor={id}>
          {caption}
        </label>
      )}
      <select
        className="input-text"
        id={id}
        name={id}
        value={value || ''}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.caption || ''}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
