import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Form from '../../components/Form';

import AppLayout from '../../layouts/AppLayout';

import { useRequest } from '../../contexts/request';
import { useNavigation } from '../../contexts/navigation';

import './index.css';

function AuthenticationPage() {
    const { t } = useTranslation(['common']);
    const { request } = useRequest();
    const { query } = useNavigation();

    const [formData, setFormData] = useState({verify: ''});
    const [autoFilled, setAutoFilled] = useState(false);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const fields = useMemo(() => [
        {
            id: 'verify',
            caption: t('verification_code', 'Verification Code'),
            datatype: 'string',
            readonly: isSubmitting,
            password: true,
        },
    ], [isSubmitting, t]);

    const onSubmit = useCallback(async (event) => {
        event.preventDefault();

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        try {
            if (formData.verify !== '') {
                await request.post('/auth/authenticate', {
                    verify: formData.verify
                });

                if ('redirect' in query) {
                    window.location = query.redirect;
                }
                else {
                    window.location = '/share';
                }
            }
            else {
                await request.post('/auth/resend_activation');
                setIsDone(true);
            }
        }
        catch (err) {
            if ('response' in err) {
                setError(err.response.data.exception);
            }
            else {
                console.error(err);
                setError(err.message);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    }, [formData, isSubmitting, query, request]);

    const handleFormChange = (newFormData) => {
        setError(null);
        setFormData(newFormData);
    };

    const skip = () => {
        if ('redirect' in query) {
            window.location = query.redirect;
        }
        else {
            window.location = '/share';
        }
    };

    const startOver = () => {
        setIsDone(false);
        setFormData({verify: ''});
    };

    useEffect(() => {
        if ('verify' in query) {
            setFormData({verify: query['verify']});
            setAutoFilled(true);
        }
    }, [query]);

    useEffect(() => {
        if (autoFilled) {
            onSubmit({preventDefault: () => {}});
            setAutoFilled(false);
        }
    }, [autoFilled, onSubmit]);

    return (
        <AppLayout>
            <Modal 
                show={isDone} 
                onHide={startOver}
                persistent={false}
            >
                <div className='success'>
                    <div className='success-title'>
                        { t('success') }
                    </div>
                    <div className='success-content'>
                        { t('verification_sent', 'Verification code has been sent to your email!') }
                    </div>
                </div>
            </Modal>
            <div className='authenticate-page-title'>
                { t('authenticate', 'Authenticate') }
            </div>

            <div className='authenticate-page-notes'>
                { t('sent_an_authentication', 'We have sent an authentication code to your email.') }
            </div>
            <form 
                className='authenticate-page-form' 
                onSubmit={onSubmit}
            >
                <Form
                    fields={fields}
                    dataSource={formData}
                    onChange={handleFormChange}
                />
                {
                    error &&
                    <div className='authenticate-page-error'>
                        { error }
                    </div>
                }
                <button 
                    className={`authenticate-page-submit ${isSubmitting ? 'authenticate-page-submit-loading' : ''}`}
                    type="submit"
                    onClick={onSubmit}
                >
                    {
                        isSubmitting &&
                        <Loader />
                    }
                    {
                        !isSubmitting && formData.verify !== '' && 
                        t('continue')
                    }
                    {
                        !isSubmitting && formData.verify === '' && 
                        t('resend', 'Resend')
                    }
                </button>
            </form>
            <div
                className='authenticate-page-link' 
                onClick={skip}
            >
                { t('skip_for_now', 'Skip for now') }
            </div>
        </AppLayout>
    );
}

export default AuthenticationPage;
