import InputField from './components/InputField';

import './index.css';

function Form(props) {
    const {
        fields=[],
        dataSource={},
        onChange=() => {}
    } = props;

    const updateForm = (key) => (value) => {
        onChange({
            ...dataSource,
            [key]: value,
        });
    };

    return (
        <>
            {
                fields.map((field) => (
                    <InputField
                        key={field.id}
                        id={field.id}
                        datatype={field.datatype}
                        caption={field.caption}
                        value={field.id in dataSource ? dataSource[field.id] : field.value}
                        options={field.options || []}
                        readonly={field.readonly}
                        multiple={field.multiple}
                        password={field.password}
                        onChange={updateForm(field.id)}
                    />
                ))
            }
        </>
    )
}

export default Form;