import { useState, createContext, useContext, useCallback, Fragment, useEffect } from 'react';

const breakRouteToPaths = (route) => {
    if (route !== '/' && route.endsWith('/')) {
        route = route.slice(0, route.length - 1);
    }

    const routeFragments = route.split('/');
    let paths = [];

    for (let routeFragment of routeFragments.slice(1)) {
        paths.push(routeFragment);
    }

    return paths;
};

const arrStartsWith = (arr1, arr2) => {
    let valid = true;

    if (arr1.length < arr2.length) {
        valid = false;
    }
    else {
        for (let i = 0; i < arr2.length; i++) {
            if (arr1[i] !== arr2[i]) {
                valid = false;
                break;
            }
        }
    }

    return valid;
};

const objToQuery = (obj) => {
    let result = '';
    for (let key in obj) {
        result += `${key}=${encodeURIComponent(obj[key])}&`
    }

    result = result.slice(0, result.length - 1);
    return result;
};

const queryToObj = (query) => {
    let queryParts = query.split('&');
    let result = {}
    for (let queryPart of queryParts) {
        let key = queryPart.split('=')[0];
        let val = queryPart.split('=')[1];
        result[key] = decodeURIComponent(val);
    }

    return result;
};

const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

const BASEURL = process.env.PUBLIC_URL || '';

function NavigationProvider(props) {
    const {
        initialRoute='/'
    } = props;

    const [route, setRoute] = useState(initialRoute);
    const [query, setQuery] = useState({});
    const navigate = useCallback((route, query={}) => {
        setRoute(route);
        setQuery(query);
        window.history.replaceState({}, null, `${BASEURL}${window.location.search ? window.location.search : ''}#${route}${Object.keys(query).length > 0 ? `?${objToQuery(query)}` : ''}`);
    }, []);

    const isRouteActive = useCallback((targetRoute) => {
        let paths = breakRouteToPaths(route);
        let targetPaths = breakRouteToPaths(targetRoute);
        return arrStartsWith(paths, targetPaths);
    }, [route]);

    useEffect(() => {
        let route = initialRoute;
        let query = {};

        if (window.location.hash) {
            let hashPart = window.location.hash.replace('#', '');
            route = hashPart.split('?')[0];

            if (hashPart.split('?').length > 1) {
                query = queryToObj(hashPart.split('?')[1]);
            }
        }

        navigate(route, query);
    }, [initialRoute, navigate]);

    return (
        <NavigationContext.Provider
            value={{
                route,
                query,
                navigate,
                isRouteActive,
            }}
            {...props}
        />
    );
}

function Router(props) {
    const {
        routes=[],
    } = props;

    const { isRouteActive } = useNavigation();

    return (
        <>
            {
                routes.map((route) => (
                    <Fragment key={route.path}>
                        {
                            isRouteActive(route.path) &&
                            route.component
                        }
                    </Fragment>
                ))
            }
        </>
    );
}

export { NavigationProvider, useNavigation, Router };