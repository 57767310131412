import { useEffect, useState } from 'react';

import './index.css';

const linearTransition = (curVal, target, setter, done) => {
    setter(curVal);

    if (curVal !== target) {
        setTimeout(() => {
            if (target > curVal) {
                linearTransition(Math.round((curVal + 0.05) * 100) / 100, target, setter, done);
            }
            else {
                linearTransition(Math.round((curVal - 0.05) * 100) / 100, target, setter, done);
            }
        }, 10);
    }
    else {
        if (done) {
            done();
        }
    }
};

function Modal(props) {
    const {
        show=false,
        persistent=false,
        onHide=() => {},
        children
    } = props;
    const [pseudoShow, setPseudoShow] = useState(show);
    const [opacity, setOpacity] = useState(0);
    const onWrapperClick = (event) => {
        const {
            target,
            currentTarget,
        } = event;
        
        if (!persistent && target === currentTarget) {
            onHide();
        }
    };

    useEffect(() => {
        if (show === true && pseudoShow === false && opacity === 0) {
            setPseudoShow(true);
            linearTransition(opacity, 1, setOpacity);
        }
        else if (show === false && pseudoShow === true && opacity === 1) {
            linearTransition(opacity, 0, setOpacity, () => setPseudoShow(false));
        }
    }, [show, pseudoShow, opacity]);

    return (
        <>
            {
                pseudoShow &&
                <div className='modal-wrapper' onMouseDown={onWrapperClick} style={{ opacity }}>
                    <div className='modal-container'>
                        { children }
                    </div>
                </div>
            }
        </>
    );
}

export default Modal;