import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Form from '../../components/Form';

import AppLayout from '../../layouts/AppLayout';

import { useRequest } from '../../contexts/request';
import { useNavigation } from '../../contexts/navigation';

import './index.css';

function ForgotPasswordPage(props) {
    const { 
        validator,
    } = props;
    
    const { t } = useTranslation(['common']);
    const { request } = useRequest();
    const { navigate, query } = useNavigation();

    const [formData, setFormData] = useState({email: ''});
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const handleSwitchPage = useCallback((page) => () => {
        if (!isSubmitting) {
            navigate(page, query);
        }
    }, [navigate, query, isSubmitting]);

    const fields = useMemo(() => [
        {
            id: 'email',
            caption: t('email_address'),
            datatype: 'string',
            readonly: isSubmitting,
            validate: [{type: 'notEmpty', params: {caption: t('email_address')}}]
        },
    ], [isSubmitting, t]);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (isSubmitting) {
            return;
        }

        const error = validator.validate(fields, formData);

        if (error) {
            setError(error);
            return;
        }

        setIsSubmitting(true);

        try {
            await request.post('/auth/logout');
        }
        catch (err) {
            // Logout to clear session
        }

        try {
            await request.post('/auth/forgot_password', {
                email: formData.email,
            });
            setIsDone(true);
        }
        catch (err) {
            if ('response' in err) {
                setError(err.response.data.exception);
            }
            else {
                console.error(err);
                setError(err.message);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (newFormData) => {
        setError(null);
        setFormData(newFormData);
    };

    const startOver = () => {
        setIsDone(false);
        setFormData({email: ''});
    };

    return (
        <AppLayout>
            <Modal 
                show={isDone} 
                onHide={startOver}
                persistent={false}
            >
                <div className='success'>
                    <div className='success-title'>
                        { t('success') }
                    </div>
                    <div className='success-content'>
                        { t('password_sent') }
                    </div>
                </div>
            </Modal>
            <div className='forgot-page-title'>
                { t('cant_sign_in') }
            </div>
            <div className='forgot-page-notes'>
                { t('help_reset_password') }
            </div>
            <form 
                className='forgot-page-form' 
                onSubmit={onSubmit}
            >
                <Form
                    fields={fields}
                    dataSource={formData}
                    onChange={handleFormChange}
                />
                {
                    error &&
                    <div className='forgot-page-error'>
                        { error }
                    </div>
                }
                <button 
                    className={`forgot-page-submit ${isSubmitting ? 'forgot-page-submit-loading' : ''}`}
                    type="submit"
                    onClick={onSubmit}
                >
                    {
                        isSubmitting &&
                        <Loader />
                    }
                    {
                        !isSubmitting && 
                        t('continue')
                    }
                </button>
            </form>
            <div className='forgot-page-notes'>
                <span 
                    className='forgot-page-link'
                    onClick={handleSwitchPage('/sign_in')}
                >
                    { t('go_back_sign_in') }
                </span>
            </div>
        </AppLayout>
    );
}

export default ForgotPasswordPage;
