import axios from 'axios';
import { useMemo, createContext, useContext } from 'react';

const RequestContext = createContext({});
const useRequest = () => useContext(RequestContext);

function RequestProvider(props) {
    const {
        baseURL
    } = props;

    const request = useMemo(() => {
        let request = axios.create({
            baseURL: baseURL,
            headers: {
                'Accept': 'application/json'
            }
        });

        return request;
    }, [baseURL]);

    return (
        <RequestContext.Provider
            value={{
                request
            }}
            {...props}
        />
    );
}

export { useRequest, RequestProvider };