import { useTranslation } from 'react-i18next';

import LogoPadcloud from './assets/img/logo_padcloud.svg';

import LanguageSelector from '../../components/LanguageSelector';

import './index.css';

function AppLayout(props) {
    const { t } = useTranslation(['common', 'app']);
    const {
        children,
    } = props;

    return (
        <div className="app-container">
            <div className='app-background'>
                <div className='app-background-title'>
                    <img alt='padcloud_logo' src={LogoPadcloud} />
                    <span className='app-title'>
                        { t('pad_system_cloud', {ns: 'app'}) }
                    </span>
                </div>
            </div>
            <div className="app-content-container">
                <div className='app-content-content'>
                    {children}
                </div>
                <div className='app-content-language'>
                    <LanguageSelector />
                </div>
            </div>
        </div>
    );
}

export default AppLayout;
