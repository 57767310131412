import CheckField from './CheckField';
import SelectField from './SelectField';
import TextField from './TextField';

function InputField(props) {
    const {
        id,
        value,
        datatype=null,
        caption=null,
        readonly=false,
        options=[],
        multiple=false,
        onChange=() => {},
        password=false,
    } = props;

    const onInputChange = (event) => {
        const {
            target
        } = event;

        onChange(target.value);
    };

    return (
        <>
            {
                datatype === 'string' && options.length === 0 &&
                <TextField 
                    id={id}
                    caption={caption}
                    value={value}
                    readonly={readonly}
                    password={password}
                    onChange={onInputChange}
                />
            }
            {
                datatype === 'string' && options.length > 0 &&
                <SelectField 
                    id={id}
                    caption={caption}
                    value={value}
                    options={options}
                    readonly={readonly}
                    onChange={onInputChange}
                />
            }
            {
                datatype === 'boolean' && multiple &&
                <CheckField 
                    id={id}
                    caption={caption}
                    value={value}
                    options={options}
                    readonly={readonly}
                    onChange={onInputChange}
                />
            }
            {
                datatype === '' &&
                <div className="input-field">
                    {
                        caption && 
                        <div className="input-label">
                            {caption}
                        </div>
                    }
                    {value || ''}
                </div>
            }
        </>
    )
}

export default InputField;