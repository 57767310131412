import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LiveChatWidget } from '@livechat/widget-react'

import { NavigationProvider, Router } from './contexts/navigation';
import { RequestProvider } from './contexts/request';

import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import AuthenticationPage from './pages/AuthenticationPage';

import { Validator } from './libs/validator';

import './App.css';

function App() {
    const { t } = useTranslation(['error']);

    const validator = useMemo(() => {
        return new Validator({
            notEmpty: (data, formData, params) => t('not_empty', {name: params.caption}),
            isIdentical: (data, formData, params, field, fields) => {
                let identicals = [];
                if (Array.isArray(params.key)) {
                    for (const key of params.key) {
                        identicals.push(`'${fields.find((identicField) => identicField.id === key).caption}'`);
                    }
                }
                else {
                    identicals.push(`'${fields.find((identicField) => identicField.id === params.key).caption}'`);
                }

                identicals = identicals.join(', ');

                return t('is_identical', {name: params.caption, identicals});
            },
            inRangeString: (data, formData, params) => t('in_range_string', {name: params.caption, min: params.min, max: params.max})
        });
    }, [t]);

    return (
        <div>
            <LiveChatWidget 
                license={process.env.REACT_APP_LIVECHAT_LICENCE} 
                group={process.env.REACT_APP_LIVECHAT_GROUP} 
            />
            <Router
                routes={[
                    {
                        path: '/sign_in',
                        component: (
                            <LoginPage 
                                validator={validator}
                            />
                        )
                    },
                    {
                        path: '/sign_up',
                        component: (
                            <RegisterPage 
                                validator={validator}
                            />
                        )
                    },
                    {
                        path: '/forgot_password',
                        component: (
                            <ForgotPasswordPage 
                                validator={validator}
                            />
                        )
                    },
                    {
                        path: '/authenticate',
                        component: (
                            <AuthenticationPage />
                        )
                    },
                ]}
            />
        </div>
    );
}

function ConfiguredApp() {
    useEffect(() => {
        document.title = process.env.REACT_APP_TITLE;
    }, []);

    return (
        <NavigationProvider initialRoute='/sign_in'>
            <RequestProvider baseURL='/api'>
                <App />
            </RequestProvider>
        </NavigationProvider>
    )
}

export default ConfiguredApp;