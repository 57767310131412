function storageGet(id, key) {
    let result = null;
    let data = window.localStorage.getItem(id);
    let item;
    
    if (data) {
        item = JSON.parse(data);

        if (!((key === undefined) || (key === null))) {
            if (key in item) {
                result = item[key];
            }
            else {
                result = null;
            }
        }
        else {
            result = item;
        }
    }
    else {
        result = null;
    }

    return result;
}

function storageSet(id, data) {
    window.localStorage.setItem(id, JSON.stringify(data));
    return true;
}

function storageUpdate(id, query) {
    let data = window.localStorage.getItem(id);
    let item = {};

    if (data) {
        item = JSON.parse(data);
    }

    for (let key in query) {
        if (query[key] !== null) {
            item[key] = query[key];
        }
    }

    window.localStorage.setItem(id, JSON.stringify(item));
    return true;
}

function storageClear() {
    window.localStorage.clear();
    return true;
}

class Storage {
    constructor(id) {
        this._id = id;
    }

    get id () {
        return this._id;
    }

    get(key) {
        return storageGet(this._id, key);
    }

    set(data) {
        storageSet(this._id, data);
    }

    update(query) {
        storageUpdate(this._id, query);
    }
}

export {
    storageClear,
    storageGet,
    storageSet,
    storageUpdate,
    Storage,
};