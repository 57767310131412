import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';

import Form from '../../components/Form';

import AppLayout from '../../layouts/AppLayout';

import { useRequest } from '../../contexts/request';
import { useNavigation } from '../../contexts/navigation';

import './index.css';

function RegisterPage(props) {
    const { 
        validator,
    } = props;

    const { t } = useTranslation(['common']);
    const { request } = useRequest();
    const { navigate, query } = useNavigation();

    const [formData, setFormData] = useState({email: '', password: '', verify_password: ''});
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSwitchPage = useCallback((page) => () => {
        if (!isSubmitting) {
            navigate(page, query);
        }
    }, [navigate, query, isSubmitting]);

    const fields = useMemo(() => [
        {
            id: 'email',
            caption: t('email_address'),
            datatype: 'string',
            readonly: isSubmitting,
            validate: [{type: 'notEmpty', params: {caption: t('email_address')}}]
        },
        {
            id: 'password',
            caption: t('password'),
            datatype: 'string',
            readonly: isSubmitting,
            password: true,
            validate: [
                {type: 'notEmpty', params: {caption: t('password')}},
                {type: 'inRangeString', params: {caption: t('password'), min: 6, max: 16}},
            ]
        },
        {
            id: 'verify_password',            
            caption: t('retype_password'),
            datatype: 'string',
            readonly: isSubmitting,
            password: true,
            validate: [
                {type: 'notEmpty', params: {caption: t('retype_password')}}, 
                {type: 'isIdentical', params: {caption: t('retype_password'), key: 'password'}}
            ]
        }
    ], [isSubmitting, t]);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (isSubmitting) {
            return;
        }

        const error = validator.validate(fields, formData);

        if (error) {
            setError(error);
            return;
        }

        setIsSubmitting(true);

        try {
            await request.post('/auth/logout');
        }
        catch (err) {
            // Logout to clear session
        }

        try {
            await request.post('/auth/register', {
                email: formData.email,
                password: formData.password,
            });

            try {
                await request.put('/user/auto_update_country', {});
            }
            catch (err) {
                console.error(err);
            }

            if ('redirect' in query) {
                window.location = query.redirect;
            }
            else {
                navigate('/authenticate');
            }
        }
        catch (err) {
            if ('response' in err) {
                setError(err.response.data.exception);
            }
            else {
                console.error(err);
                setError(err.message);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (newFormData) => {
        setError(null);
        setFormData(newFormData);
    };

    return (
        <AppLayout>
            <div className='register-page-title'>
                { t('sign_up') }
            </div>
            <form 
                className='register-page-form' 
                onSubmit={onSubmit}
            >
                <Form
                    fields={fields}
                    dataSource={formData}
                    onChange={handleFormChange}
                />
                {
                    error &&
                    <div className='register-page-error'>
                        { error }
                    </div>
                }
                <button 
                    className={`register-page-submit ${isSubmitting ? 'register-page-submit-loading' : ''}`}
                    type="submit"
                    onClick={onSubmit}
                >
                    {
                        isSubmitting &&
                        <Loader />
                    }
                    {
                        !isSubmitting && 
                        t('continue')
                    }
                </button>
            </form>
            <div className='register-page-notes'>
                <span className='register-page-notes-text'>
                    { t('have_account') } 
                </span>
                &nbsp;
                <span 
                    className='register-page-link'
                    onClick={handleSwitchPage('/sign_in')}
                >
                    { t('sign_in') }
                </span>
            </div>
        </AppLayout>
    );
}

export default RegisterPage;
