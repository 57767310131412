import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';

import Form from '../../components/Form';

import AppLayout from '../../layouts/AppLayout';

import { useNavigation } from '../../contexts/navigation';
import { useRequest } from '../../contexts/request';

import './index.css';

function LoginPage(props) {
    const { 
        validator,
    } = props;

    const { t } = useTranslation(['common', 'app']);
    const { navigate, query } = useNavigation();
    const { request } = useRequest();

    const [formData, setFormData] = useState({email: '', password: ''});
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSwitchPage = useCallback((page) => () => {
        if (!isSubmitting) {
            navigate(page, query);
        }
    }, [navigate, query, isSubmitting]);

    const fields = useMemo(() => [
        {
            id: 'email',
            caption: t('email_address'),
            datatype: 'string',
            readonly: isSubmitting,
            validate: [{type: 'notEmpty', params: {caption: t('email_address')}}]
        },
        {
            id: 'password',
            caption: <>
                <div>
                    { t('password') }
                </div>
                <div
                    className='login-page-link' 
                    onClick={handleSwitchPage('/forgot_password')}
                >
                    { t('forgot_password') }
                </div>
            </>,
            datatype: 'string',
            readonly: isSubmitting,
            password: true,
            validate: [{type: 'notEmpty', params: {caption: t('password')}}]
        },
    ], [isSubmitting, handleSwitchPage, t]);

    const onSubmit = async (event) => {
        event.preventDefault();

        if (isSubmitting) {
            return;
        }

        const error = validator.validate(fields, formData);

        if (error) {
            setError(error);
            return;
        }

        setIsSubmitting(true);
        
        try {
            await request.post('/auth/logout');
        }
        catch (err) {
            // Logout to clear session
        }

        try {
            await request.post('/auth/login', {
                username: formData.email,
                password: formData.password,
            });

            const response = await request.get('/user');
            const user = response.data;

            if (Object.keys(user.country).length === 0) {
                try {
                    await request.put('/user/auto_update_country', {});
                }
                catch (err) {
                    console.error(err);
                }
            }

            if (user.is_authenticated) {
                if ('redirect' in query) {
                    window.location = query.redirect;
                }
                else {
                    window.location = '/share';
                }
            }
            else {
                navigate('/authenticate')
            }
        }
        catch (err) {
            if ('response' in err) {
                setError(err.response.data.exception);
            }
            else {
                console.error(err);
                setError(err.message);
            }
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleFormChange = (newFormData) => {
        setError(null);
        setFormData(newFormData);
    };

    return (
        <AppLayout>
            <div className='login-page-title'>
                { t('sign_in') }
            </div>
            <form 
                className='login-page-form' 
                onSubmit={onSubmit}
            >
                <Form
                    fields={fields}
                    dataSource={formData}
                    onChange={handleFormChange}
                />
                {
                    error &&
                    <div className='login-page-error'>
                        { error }
                    </div>
                }
                <button 
                    className={`login-page-submit ${isSubmitting ? 'login-page-submit-loading' : ''}`}
                    type="submit"
                    onClick={onSubmit}
                >
                    {
                        isSubmitting &&
                        <Loader />
                    }
                    {
                        !isSubmitting && 
                        t('continue')
                    }
                </button>
            </form>
            <div className='login-page-notes'>
                <span className='login-page-notes-text'>
                    { t('dont_have_account') } 
                </span>
                &nbsp;
                <span 
                    className='login-page-link'
                    onClick={handleSwitchPage('/sign_up')}
                >
                    { t('sign_up') }
                </span>
            </div>
            <div className='login-page-notes-text'>
                { t('pad_system_cloud_used_to_be_padshare', {ns: 'app'}) }
            </div>
        </AppLayout>
    );
}

export default LoginPage;
